import { isElementInViewport, select, selectAll } from "./client/dom-utils.ts";
import {
  type CountEvent,
  type MessageEvent,
  parseEvent,
} from "./lib/tanuki-event.ts";

const chatElement = select(document, "#chat");
const scrollAnchor = select(document, "#scroll-anchor");
const messageTemplate = select(
  document,
  "#chat-message",
) as HTMLTemplateElement;

const clientSupportsAnchor = CSS.supports("overflow-anchor: none");

// Code copied from https://css-tricks.com/books/greatest-css-tricks/pin-scrolling-to-bottom/
// Safari support
const config = { childList: true };

// TODO: Handle scroll up in Safari
const observer = new MutationObserver(function (mutationsList) {
  for (let mutation of mutationsList) {
    if (mutation.type === "childList") {
      // If we are in a browser which supports client anchor, we can disconnect
      // the observer after an initial scroll
      if (clientSupportsAnchor) {
        if (!isElementInViewport(scrollAnchor)) {
          observer.disconnect();
        }
      }
      scrollAnchor.scrollIntoView();
    }
  }
});

observer.observe(chatElement, config);

// TODO: Get proper formatter based on browser preference
const formatter = new Intl.DateTimeFormat("en-US", {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hourCycle: "h23",
});

const colors = [
  "tc-text-green-300",
  "tc-text-amber-300",
  "tc-text-blue-300",
  "tc-text-red-300",
  "tc-text-orange-300",
  "tc-text-yellow-300",
  "tc-text-lime-300",
  "tc-text-emerald-300",
  "tc-text-teal-300",
  "tc-text-cyan-300",
  "tc-text-sky-300",
  "tc-text-indigo-300",
  "tc-text-violet-300",
  "tc-text-purple-300",
  "tc-text-fuchsia-300",
  "tc-text-fuchsia-300",
  "tc-text-pink-300",
  "tc-text-rose-300",
];

const colorMap: Map<string, string> = new Map();

function getColorFromString(s: string): string {
  if (colorMap.has(s)) {
    return colorMap.get(s) ?? "tc-text-gray-200";
  }
  let i = 0;
  for (const char of s) {
    i += char.codePointAt(0) ?? 0;
  }
  const color = colors[i % colors.length];
  colorMap.set(s, color);
  return color;
}

function createMessage(msg: MessageEvent) {
  const { messageType, author, message, datetime } = msg;
  const node = messageTemplate.content.cloneNode(true) as DocumentFragment;
  const mNode = select(node, ".message");
  mNode.textContent = message.replace(/([\/_])/g, "$1\u200b");
  const nNode = select(node, ".name");
  nNode.textContent = author;
  nNode.classList.add(getColorFromString(author));
  /* Only initial events ("I") get their "real" time stamps.
     Otherwise, we just fake a date, to make  */
  const date = messageType === "I" ? new Date(datetime) : new Date();
  select(node, ".time").textContent = formatter.format(date);

  chatElement.insertBefore(node, scrollAnchor);
}

function updateCounts(msg: CountEvent) {
  const text = `${msg.count}\xa0${msg.count === 1 ? "Viewer" : "Viewers"}`;
  for (const node of selectAll(document, ".viewer-count")) {
    node.textContent = text;
  }
}

let socket: WebSocket | null;
let tries = 0;

function startSocket() {
  tries += 1;
  socket = new WebSocket(
    `${window.location.protocol.replace("http", "ws")}//${window.location.host}/-/chat`,
  );

  // message is received
  socket.addEventListener("message", (event) => {
    if (event.data === "RELOAD") {
      window.location.reload();
      return;
    }
    const e = parseEvent(event.data.toString());
    if (e.messageType === "C") {
      updateCounts(e);
    } else {
      createMessage(e);
    }
  });

  // socket opened
  socket.addEventListener("open", (event) => {
    console.warn("open", event);
    tries = 0;
  });

  // socket closed
  socket.addEventListener("close", (event) => {
    console.log("Disconnected");
    console.warn(document.visibilityState);
    console.warn(event);
    if (document.visibilityState === "visible") {
      console.warn("Document visible. Trying to restart.");
      return restart();
    }
    if (IN_DEV) {
      console.warn("In Dev Mode, always reconnecting");
      return restart();
    }
  });

  // error handler
  socket.addEventListener("error", (event) => {
    console.warn("error", event);
  });

  function restart() {
    if (tries >= 5) {
      // TODO: Show warning in the UI
      console.warn("Too many restarts");
      return;
    }

    setTimeout(
      () => {
        startSocket();
      },
      Math.min(25, 2 ** tries) * 1000,
    );
  }
}

startSocket();
