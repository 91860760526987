import { type TanukiEvent } from "../db/schema.ts";

const allMessageTypes = ["U", "I", "C"] as const;
/**
 * - I: Initial events, sent once a client connects
 * - U: Updated events, sent once a client is connected and receiving
 * - C: User Count
 */
type MessageType = (typeof allMessageTypes)[number];

function assertMessageType(obj: any): asserts obj is MessageType {
  if (allMessageTypes.indexOf(obj) < 0) {
    throw new Error(`Unknown message type: ${allMessageTypes}`);
  }
}
type SerializedMessage = `${MessageType}||${string}`;

export interface MessageEvent extends TanukiEvent {
  messageType: "I" | "U";
}

export interface CountEvent {
  messageType: "C";
  count: number;
}

type EventWithType = MessageEvent | CountEvent;

export function serializeEvent(
  e: TanukiEvent,
  type: MessageType = "U",
): SerializedMessage {
  return `${type}||${e.id}||${e.datetime}||${e.author}||${e.message}`;
}

export function serializeCountEvent(count: number) {
  return `C||${count}`;
}

export function parseEvent(msg: string): EventWithType {
  const messageType = msg.substring(0, 1);
  assertMessageType(messageType);

  if (messageType === "C") {
    return {
      messageType,
      count: parseInt(msg.substring(3)) || 0,
    };
  }

  const [, id, datetime, author, message] = msg.split("||", 5);

  return {
    messageType,
    id,
    author,
    message,
    datetime: parseInt(datetime, 10) || 0,
  };
}
